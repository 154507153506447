
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'grid__item' }, _createElement('div', { 'className': 'card-wrapper underline-links-hover' }, _createElement('div', { 'className': 'card card--standard card--media' }, _createElement('div', {
                    'className': 'card__inner color-background-2 ratio',
                    'style': { ratioPercent: '100%' }
                }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'motion-reduce',
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__badge bottom left' }, this.out_of_stock ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-inverse',
                    'key': '626'
                }, 'Sold out') : null), _createElement('div', { 'className': 'card__badge top left' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-1',
                    'key': '795'
                }, 'Sale') : null))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'price_wrap' }, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, _createElement('div', { 'className': 'price__regular' }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))), _createElement('div', { 'className': 'price__sale' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-item price-item--last price-item--sale' }, '\n                      ', this.formatPrice(this.price), '\n                    ')))), _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('div', {
                        'className': 'prod_details',
                        'key': '21091'
                    }, _createElement('a', {
                        'href': this.url,
                        'className': 'btn',
                        'aria-label': 'button'
                    }, '\n                      ', this.out_of_stock ? 'Sold out' : 'Select options', '\n                    '))] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '24701'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '24703'
                    }),
                    _createElement('button', {
                        'className': 'prod_details cm_add-to-cart',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': this.out_of_stock,
                        'key': '24705'
                    }, _createElement('span', {
                        'href': this.url,
                        'className': 'btn'
                    }, this.out_of_stock ? 'Sold out' : 'Add to cart'))
                ] : null), _createElement('div', { 'className': 'prod_details' }, _createElement('a', {
                    'href': this.url,
                    'className': 'btn'
                }, 'Details')))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []