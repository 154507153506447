const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = globalThis.Convermax.config?.wheelsFields || [
  'wheel_diameter',
  'wheel_width',
  'wheel_bolt_pattern',
];
const tiresFields = globalThis.Convermax.config?.tiresFields || ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const isVehicleLanding = !!globalThis.Convermax.config?.isVehicleLanding;
const vehicleLandingPageView = globalThis.Convermax.config?.vehicleLandingPageView ?? 'products';

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm-brand-list, #cm_manufacturers')) {
    const unwantedPageWrappers = [
      ['div.rte', 'rte'],
      ['.page__content.rte', 'rte'],
      ['.page-width.page-width--narrow', 'page-width--narrow'],
    ];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(className);
    });
  }
}

const updateCallback = () => {
  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  searchPageUrl: '/pages/search',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: globalThis.Convermax.config?.pageSize || 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  product: {
    searchResultsImageWidth: 180,
    searchResultsImageHeight: 180,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: '/collections', field: 'category' },
      {
        pathname: window.document.querySelector('#cm-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
      {
        pathname:
          isVehicleLanding && vehicleLandingPageView === 'categories' ? window.location.pathname : null,
        field: 'category',
      },
    ],
    categorySelectionPageRedirect: !!window.Convermax.config?.categorySelectionPageRedirect,
    isAutoRedirectDisabled: !!window.Convermax.config?.isAutoRedirectDisabled,
    isVehicleSelectionIsolated: !!window.Convermax.config?.isVehicleSelectionIsolated,
    hideVehicleFromUrl: !!window.Convermax.config?.hideVehicleFromUrl,
    vehicleDataExpires: window.Convermax.config?.vehicleDataExpires,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'MainContent',
      visibleIf: () => !isVehicleLanding || vehicleLandingPageView === 'products',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '#cm-collection-facets #cm-facets',
      template: 'FacetPanelContainer',
      visibleIf: () => !isVehicleLanding || vehicleLandingPageView === 'products',
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      sortSelectClass: 'select__select caption-large',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm-search-box', class: 'search-modal__form' },
      template: 'searchBox/dialogButtonAdaptable',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'SearchBoxDialogButton',
      location: { insertAfter: '#cm-mobile-search-box', class: 'cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      columnBreakpoint: 800,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      columnBreakpoint: 800,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: '#cm-mobile-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: { selector: '#cm-single-garage', class: 'cm-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: { selector: '#cm-mobile-single-garage', class: 'cm-mobile-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      location: { selector: '#cm-verify-fitment', class: 'cm_vehicle-widget__verify-fitment_container' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitmentStub',
      type: 'VerifyFitment',
      location: '#cm-verify-fitment-stub',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'VehicleLandingPageCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => isVehicleLanding && vehicleLandingPageView === 'categories',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
  ],
};
